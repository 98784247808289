export default [{
    header: 'Reporteria',
    icon: 'PieChartIcon',
    children: [{
        title: 'Formularios Completados por Rubro',
        route: 'admin-reporteria-1',
        icon: 'ListIcon'
    }, {
        title: 'Formularios por Estado',
        route: 'admin-reporteria-2',
        icon: 'PieChartIcon'
    }, {
        title: 'Formularios por Nivel',
        route: 'admin-reporteria-3',
        icon: 'BarChart2Icon'
    }, {
        title: 'Formularios por Rubro y Proceso',
        route: 'admin-reporteria-4',
        icon: 'ListIcon'
    }, {
        title: 'Formularios por Tarifario',
        route: 'admin-reporteria-5',
        icon: 'ListIcon'
    }, {
        title: 'Informes Emitidos por Rubro',
        route: 'admin-reporteria-6',
        icon: 'ListIcon'
    }, {
        title: 'Proveedores por Nivel',
        route: 'admin-reporteria-7',
        icon: 'ListIcon'
    }, {
        title: 'Proveedores por Rubro',
        route: 'admin-reporteria-8',
        icon: 'PieChartIcon'
    }, {
        title: 'Análisis de PU',
        route: 'admin-reporteria-9',
        icon: 'ListIcon'
    }, {
        title: 'Resultados Rev. Lab.',
        route: 'admin-reporteria-10',
        icon: 'ListIcon'
    }, {
        title: 'Criticidad Laboral',
        route: 'admin-reporteria-11',
        icon: 'ListIcon'
    }, {
        title: 'Observaciones',
        route: 'admin-reporteria-12',
        icon: 'ListIcon'
    }, {
        title: 'Reporte Diferencia Salarial',
        route: 'admin-reporteria-13',
        icon: 'BarChart2Icon'
    }]
}]