export default [{
    header: 'Calendario',
    icon: 'CalendarIcon',
    children: [{
        title: 'Fecha de Evaluaciones',
        route: 'reviser-calendar',
        icon: 'BookOpenIcon',        
        action: 'read',
        resource: 'ACL'
    }],
    
    
}]