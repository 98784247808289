import home from './home';
import revisersAdmin from './admin/revisers';
import providersAdmin from './admin/providers';
import calendarsAdmin from './admin/calendars';
import reporteriaAdmin from './admin/reporteria';

export default [
    ...home,
    ...providersAdmin,
    ...revisersAdmin,
    ...calendarsAdmin,
    ...reporteriaAdmin,
];